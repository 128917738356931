<template>
  <div class="supply-evaluation-list-container">
    <div class="search-form-container">
      <el-form ref="searchForm" :model="tableQueryData" inline label-width="120px">
        <el-form-item label="是否回复评价:">
          <el-select v-model="tableQueryData.data.isEvaluationReply" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否回复投诉:">
          <el-select v-model="tableQueryData.data.isSuggestReply" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务订单号:">
          <el-input v-model="tableQueryData.data.serviceNo" placeholder="输入服务订单号"></el-input>
        </el-form-item>
        <el-form-item label="评价时间:">
          <el-date-picker
            v-model="createTimes"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="handleUnfold">{{ moreConditionName }}</el-button>
          <el-button type="primary" @click="handleSerch">查询</el-button>
          <el-button icon="el-icon-refresh-left" @click="handleReset">重置</el-button>
        </el-form-item>
        <transition-group name="fade" style="display: block">
          <template v-if="isMoreCondition">
            <el-form-item label="评价回复时间:" :key="0">
              <el-date-picker
                v-model="evaluationReplyTimes"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="投诉回复时间:" :key="1">
              <el-date-picker
                v-model="suggestReplyTime"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="供应商ID:" :key="2">
              <el-input v-model="tableQueryData.data.storeId" placeholder="供应商ID"></el-input>
            </el-form-item>
            <el-form-item label="供应商名称:" :key="3">
              <el-input v-model="tableQueryData.data.storeName" placeholder="供应商名称"></el-input>
            </el-form-item>
            <el-form-item label="超星评价:" :key="4">
              <el-select v-model="tableQueryData.data.is6Star" placeholder="请选择">
                <el-option label="全部" value=""></el-option>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
          </el-form-item>
          </template>
        </transition-group>
      </el-form>
    </div>
    <div class="operate-container">
      <!-- <el-button plain icon="el-icon-upload2" @click="handleExport">导出</el-button> -->
      <el-button plain @click="handleBatchShowOrHiden(1)">隐藏</el-button>
      <el-button plain @click="handleBatchShowOrHiden(0)">显示</el-button>
      <!-- <el-button plain @click="handleBatchDelete()">删除</el-button> -->
    </div>
    <LTable
      :tableLoading="tableLoading"
      :tableData="tableData"
      :tableHeader="tableHeader"
      :tableQueryData="tableQueryData"
      :total="total"
      :getTableList="getTableList"
      :selectTableData.sync="selectTableData"
      isPagination
    >
      <template slot="serviceNo" slot-scope="scope">
        <el-link type="primary" @click="handleLink(scope.row.serviceId)">{{ scope.row.serviceNo }}</el-link>
      </template>
      <template slot="environmentScore" slot-scope="scope">
        <el-rate :disabled="true" :allow-half="true" v-model="scope.row.environmentScore"></el-rate>
      </template>
      <template slot="serviceScore" slot-scope="scope">
        <el-rate :disabled="true" :allow-half="true" v-model="scope.row.serviceScore"></el-rate>
      </template>
      <template slot="satisfactions" slot-scope="scope">
        <span v-if="scope.row.satisfactions">是</span>
        <span v-else>否</span>
      </template>
      <template slot="isHide" slot-scope="scope">
        <span :class="scope.row.isHide == 0 ? 'show-color' : 'hide-color'">{{ scope.row.isHide ? '隐藏' : '显示' }}</span>
      </template>
      <template slot="operate" slot-scope="scope">
        <el-button class="table-btn" type="text" @click="handleDetail(scope.row)">详情</el-button>
        <span class="table-btn-line"></span>
        <template v-if="!scope.row.suggestReply">
          <el-button class="table-btn" type="text" @click="handleReply(scope.row)">回复</el-button>
          <span class="table-btn-line"></span>
        </template>
        <el-button class="table-btn" type="text" @click="handleShowOrHiden(scope.row)" v-if="scope.row.isHide == 1">显示</el-button>
        <el-button class="table-btn" type="text" @click="handleShowOrHiden(scope.row)" v-if="scope.row.isHide == 0">隐藏</el-button>
        <!-- <span class="table-btn-line"></span> -->
        <!-- <el-button class="table-btn" type="text" @click="handleDelete(scope.row)">删除</el-button> -->
      </template>
    </LTable>
  </div>
</template>

<script>
import { getEvaluateQuery, changeEvaluateStatus, batchChangeEvaluateStatus, deleteEvaluate, batchDeleteEvaluate } from '@/api/evaluate';
export default {
  data() {
    return {
      isMoreCondition: false,
      moreConditionName: '展开',
      tableLoading: false,
      tableHeader: [
        { label: 'selection' },
        { label: '序号', width: '80', align: 'center' },
        { label: '服务订单号', prop: 'serviceNo', width: '220', align: 'center', render: true },
        { label: '服务供应商', prop: 'storeName', align: 'center', minWidth: '300' },
        { label: '供应商ID', prop: 'storeId', width: '80', align: 'center' },
        { label: '整体环境评分', prop: 'environmentScore', align: 'center', width: '160', render: true, noTooltip: true },
        { label: '整体服务评分', prop: 'serviceScore', align: 'center', width: '160', render: true, noTooltip: true },
        { label: '超星评价', prop: 'satisfactions', align: 'center', minWidth: '80', render: true },
        { label: '评价', prop: 'evaluation', align: 'center', minWidth: '200' },
        { label: '投诉建议', prop: 'suggest', align: 'center', minWidth: '200' },
        { label: '评价时间', prop: 'createTime', align: 'center', width: '160' },
        { label: '评价回复时间', prop: 'evaluationReplyTime', align: 'center', width: '160' },
        { label: '投诉回复时间', prop: 'suggestReplyTime', align: 'center', width: '160' },
        { label: '显示状态', prop: 'isHide', align: 'center', width: '80', render: true },
        { label: '操作', prop: 'operate', align: 'center', width: '180', render: true, fixed: 'right' },
      ],
      total: 0,
      tableQueryData: {
        pageNumber: 1,
        pageSize: 10,
        data: {
          isEvaluationReply: undefined,
          isSuggestReply: undefined,
          serviceNo: undefined,
          storeId: undefined,
          evaluation: undefined,
          createTimeStart: undefined, //评价时间起
          createTimeEnd: undefined, //评价时间止
          evaluationReplyTimeStart: undefined, //评价回复时间起
          evaluationReplyTimeEnd: undefined, //评价回复时间止
          suggestReplyTimeStart: undefined, //投诉回复时间起
          suggestReplyTimeEnd: undefined, //投诉回复时间止
          storeId: undefined,
          storeName: undefined,
          is6Star: undefined // 是否是超星
        },
      },
      tableData: [], //供应商分页列表

      createTimes: [],
      evaluationReplyTimes: [],
      suggestReplyTime: [],

      selectTableData: [],
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    async getTableList() {
      try {
        this.tableLoading = true;
        let query = JSON.parse(JSON.stringify(this.tableQueryData));
        if (!!this.createTimes && this.createTimes.length) {
          query.data['createTimeStart'] = this.createTimes[0] || undefined;
          query.data['createTimeEnd'] = this.createTimes[1] || undefined;
        }
        if (!!this.evaluationReplyTimes && this.evaluationReplyTimes.length) {
          query.data['evaluationReplyTimeStart'] = this.evaluationReplyTimes[0] || undefined;
          query.data['evaluationReplyTimeEnd'] = this.evaluationReplyTimes[1] || undefined;
        }
        if (!!this.suggestReplyTime && this.suggestReplyTime.length) {
          query.data['suggestReplyTimeStart'] = this.suggestReplyTime[0] || undefined;
          query.data['suggestReplyTimeEnd'] = this.suggestReplyTime[1] || undefined;
        }
        let { data } = await getEvaluateQuery(query);
        this.total = Number(data.total);
        this.tableData = data.content;
        this.tableLoading = false;
      } catch (error) {
        this.tableLoading = false;
      }
    },
    handleSerch() {
      this.getTableList();
    },
    handleReset() {
      Object.assign(this.$data.tableQueryData, this.$options.data().tableQueryData);
      this.createTimes = [];
      this.evaluationReplyTimes = [];
      this.suggestReplyTime = [];
      this.getTableList();
    },
    handleExport() {
      alert('正在开发中，敬请期待！');
    },
    handleLink(id) {
      // this.$router.push({ path: '/orderDetail', query: { serviceId: id } });
      // window.history.pushState(null, null, `/index/supplyorder/orderDetail?serviceId=${id}`)

      window.history.pushState(null, null, `/index/service/serviceOrder/serviceParticulars?id=${id}`);
    },
    handleDetail(scope) {
      this.$router.push({ path: '/detailEvaluationList', query: { id: scope.evaluationId } });
    },
    handleReply(scope) {
      this.$router.push({ path: '/replyEvaluationList', query: { id: scope.evaluationId } });
    },
    handleUnfold() {
      this.isMoreCondition = !this.isMoreCondition;
      this.isMoreCondition ? (this.moreConditionName = '收起') : (this.moreConditionName = '展开');
    },
    async handleShowOrHiden(row) {
      await changeEvaluateStatus({ data: row.evaluationId });
      this.$notify.success({ title: '成功', message: '操作成功', duration: 2000 });
      this.getTableList();
    },
    async handleBatchShowOrHiden(hideFlag) {
      if (!this.selectTableData.length) {
        this.$notify.warning({ title: '提示', message: '请至少选择一条需要操作的数据！', duration: 2000 });
        return;
      }
      const ids = this.selectTableData.map((item) => parseInt(item.evaluationId));
      await batchChangeEvaluateStatus({
        data: {
          hideFlag: hideFlag,
          evaluationIds: ids,
        },
      });
      this.$notify.success({ title: '成功', message: '操作成功', duration: 2000 });
      this.getTableList();
    },
    async handleDelete(row) {
      this.$confirm('是否确认删除当前评价?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteEvaluate({ data: row.evaluationId });
          this.$notify.success({ title: '成功', message: '删除成功', duration: 2000 });
          this.getTableList();
        })
        .catch(() => {});
    },
    async handleBatchDelete() {
      if (!this.selectTableData.length) {
        this.$notify.warning({ title: '提示', message: '请至少选择一条需要操作的数据！', duration: 2000 });
        return;
      }
      const ids = this.selectTableData.map((item) => parseInt(item.evaluationId));
      await batchDeleteEvaluate({
        data: ids,
      });
      this.$notify.success({ title: '成功', message: '删除成功', duration: 2000 });
      this.getTableList();
    },
  },
};
</script>

<style lang="scss" scoped>
.supply-evaluation-list-container {
  background-color: #fff;
  .operate-container {
    margin-bottom: 12px;
  }
  .show-color {
    color: #70b603;
  }
  .hide-color {
    color: red;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
