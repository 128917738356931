import request from '@/utils/request';
// import requestTest from '@/utils/requestTest';


const handleApi = (url) => {
  return `evaluation-web-api/admin${url}`
}

export function getEvaluateQuery(data) {
  return request({
    url: handleApi('/evaluation/getEvaluationPage'),
    method: 'POST', data
  })
} 
// export function getEvaluateQuery(data) {
//   return requestTest({
//     url: '/admin/evaluation/getEvaluationPage',
//     method: 'POST', data
//   })
// } 
//获取评价分页列表
export function getEvaluateDetail(data) {
  return request({
    url: handleApi('/evaluation/getEvaluationInfoById'),
    method: 'POST', data
  })
} 
// export function getEvaluateDetail(data) {
//   return requestTest({
//     url: handleApi('/getEvaluationInfoById'),
//     method: 'POST', data
//   })
// } 
//获取评价详情
export function evaluateReply(data) {
  return request({
    url: handleApi('/evaluation/saveSuggestReply'),
    method: 'POST',
    data
  })
} 
// export function evaluateReply(data) {
//   return requestTest({
//     url: handleApi('/evaluation/saveSuggestReply'),
//     method: 'POST',
//     data
//   })
// } 
//评价回复
export function deleteEvaluate(data) {
  return request({
    url: handleApi('/evaluation/delEvaluation'),
    method: 'POST',
    data
  })
} //删除评价
export function batchDeleteEvaluate(data) {
  return request({
    url: handleApi('/evaluation/delBatchEvaluation'),
    method: 'POST',
    data
  })
} //批量删除评价
export function changeEvaluateStatus(data) {
  return request({
    url: handleApi('/evaluation/changeIsHide'),
    method: 'POST',
    data
  })
} //隐藏显示评价
export function batchChangeEvaluateStatus(data) {
  return request({
    url: handleApi('/evaluation/changeBatchIsHide'),
    method: 'POST',
    data
  })
}










//整体情况
export function getOverallSituation(data) {
  return request({
    url: handleApi('/evaluation/getEvaluationStatistics'),
    method: 'POST',
    data
  })
} //获取整体情况统计
export function getCitys(data) {
  return request({
    url: 'misc-web-api/common/district/queryById',
    method: 'post', data
  })
} //获取城市列表
export function getStoreList(data) {
  return request(
    {
      url: 'supplier-web-api/admin/store/getStoreByArea',
      method: 'POST', data
    }
  )
}

